<template>
    <!-- модальное окно удаления аккаунта -->
    <modal @close="$emit('close')">
        <template v-slot:title>{{ $t('ForRemoval') }}</template>
        <template v-slot:head-bottom>
            <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email" @click="closeModal"><i class="ri-close-fill align-bottom"></i></button>
        </template>
        <template v-slot:body>
            <div class="alert alert-warning" role="alert" v-if="form.daysLeft > 0">
                <span class="fs-14">
                    <b>{{ $t('confirmDayDeletion') }} {{ this.form.daysLeft }} {{ $t('days') }}</b>
                </span>
            </div>
            <b-row>
                <b-col lg="6" v-for="item in blocks" :key="item">
                    <b-card>
                        <div class="card-header">
                            <h5 class="card-title mb-0"><b>{{ $t(item.title) }}</b></h5>
                        </div>
                        <div class="card-body">
                            <div class="mb-3" v-for="ch in item.items" :key="ch">
                                <div class="d-flex mt-4" >
                                    <div class="flex-shrink-0 avatar-xs align-self-center me-3" >
                                        <div :class="`avatar-title bg-${ch.color} rounded-circle fs-16 text-white`" >
                                            <i :class="ch.icon"></i>
                                        </div>
                                    </div>
                                    <div :class="`flex-grow-1 ${ch.copy == true ? 'c_block' : ''} overflow-hidden`" >
                                        <p class="mb-1 fs-16">{{ $t(ch.title) }} :</p>
                                        <div class="ctext-wrap" style="display:flex">
                                            <div class="ctext-wrap-content">
                                                <h6 class="text-truncate mb-0 fs-15" >{{ch.lang != null ? $t(form[ch.lang]) : form[ch.value] ? form[ch.value] : $t('Null')  }}</h6>
                                            </div>
                                            <i v-if="ch.copy == true" class="ri-file-copy-2-fill fs-14 copybtn" @click="copy(ch.lang == null ? form[ch.value] : $t(form[ch.lang]))"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-card>
                </b-col>
            </b-row>
        </template>
        <template v-slot:footer-bottom>
            <!-- <button class="btn link-success fw-medium" data-bs-dismiss="modal" @click="$emit('close')"><i class="ri-close-line me-1 align-middle"></i> {{ $t('close') }} </button> -->
            <button 
                type="button" 
                v-if="this.form.canDelete == true"
                class="btn btn-danger r waves-effect waves-light">
                {{ $t('Delete') }}
            </button>
            <button 
                type="button"
                :class="`btn btn-success ss waves-effect waves-light`">
                {{ $t('Restore') }}
            </button>
        </template>
    </modal>

    <modalremove 
        v-if="dialogShow" 
        @close="dialogShow = false" 
        @remove="remove()" 
    />


</template>

<script>
import modal from '@/components/modal/modal-lg'
import modalremove from '@/components/modal/modalremove'
import { SuperAdmin } from '@/API'
import {copy} from '@/usabilityScripts/copy.js'

let apiServe = new SuperAdmin();

export default ({
    data() {
        return {
            form: "",
            dialogShow: false,
            blocks: [
                {
                    title: "Account",
                    items: [
                        {
                            title: "AccountID",
                            value: "accountId",
                            lang: null,
                            icon: "ri-hashtag",
                            color: "info",
                            copy: true
                        },
                        {
                            title: "name",
                            value: "userName",
                            lang: null,
                            icon: "bx bxs-user",
                            color: "info",
                            copy: true
                        },
                        {
                            title: "AccountDeletionDate",
                            value: "dateOfDelete",
                            lang: null,
                            icon: "bx bx-time",
                            color: "info",
                            copy: true
                        }
                    ]
                },
                {
                    title: "PersonalInformation",
                    items: [
                        {
                            title: "phone_num",
                            value: "ownerPhone",
                            lang: null,
                            icon: "bx bxs-phone",
                            color: "primary",
                            copy: true
                        },
                        {
                            title: "mail",
                            value: "ownerEmail",
                            lang: null,
                            icon: "ri-mail-check-line",
                            color: "primary",
                            copy: true
                        },
                        {
                            title: "country",
                            value: "country",
                            lang: null,
                            icon: "bx bx-comment-dots",
                            color: "primary",
                            copy: true
                        },
                        {
                            title: "lang",
                            value: "language",
                            lang: null,
                            icon: "bx bx-comment-dots",
                            color: "primary",
                            copy: true
                        },
                    ]
                }
            ],
        }
    },
    props: ['objcard'],
    components: { modal, modalremove },
    created(){
        this.getdata();
    },
    methods: {
        getdata(){
            this.form = this.objcard
        },
        closeModal() {
            this.$emit('close');
        },
        remove(){
            apiServe.deleteAccount().then(result => {
                if(result.status == 'done'){
                    this.$toast.success('done')
                }
            })
        },
        restore(){
            apiServe.restoreAccount().then(result => {
                if(result.status == 'done'){
                    this.$toast.success('done')
                }
            })
        },
        copy(item){
            // копіювання
            if(copy(item) == true) {
                this.$toast.success(this.$t('Copied'));
            } else {
                this.$toast.error(`Fucking mistake, contact Borys`);
            }
            return
        },
    }
})
</script>

<style scoped>
.copybtn {
    cursor: pointer;
    margin-top: -3px;
    margin-left: 5px;
    opacity: 0;
    transition: opacity .3s;
}
.c_block:hover .copybtn {
    opacity: 1;
    transition: opacity .3s;
}

.copybtn:checked {
    width: 500px;
}
</style>
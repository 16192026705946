<template>
    <PageHeader :title="title" />

    <!-- компонент таблицы -->
    <tablecustom
        :typesearch="typesearch" 
        :objParams="objParams"
        @search="searchB" 
        :columns="columns" 
        :rows="rows"
        @getdata="getdata"
        @changelimit="changelimit"
        @open="open" 
    />
    
    <viewbox 
        v-if="showModal" 
        @close="showModal = false" 
        :objcard="objcard"
    />
</template>

<script>
import PageHeader from "@/components/page-header";
import tablecustom from '@/components/globaltable/index';
import viewbox from './view.vue'
import { SuperAdmin } from '@/API.js';
import { storeS } from "@/store";
import { mutateStatusDel } from '@/usabilityScripts/globalMutate.js'

let apiServe = new SuperAdmin();

export default {
    components: {
        PageHeader,
        tablecustom,
        viewbox
    },
    data() {
        return {
            title: this.$t('AccountstoDeleted'),
            search: '',
            showModal: false,
            objParams:{
                page: '1',
                pageLimit: '10',
                searchMethod: '',
                search: '',
            },
            columns: [
                {
                    name: this.$t('AccountID'),
                    text: "accountId",
                    align: "left",
                    status: false
                },
                {
                    name: this.$t("name"),
                    text: "userName",
                    align: "left",
                    status: true,
                    mutate: (item) => item ? item : this.$t('Null')
                },
                {
                    name: this.$t("phone_num"),
                    text: "ownerPhone",
                    align: "right",
                    status: true
                },
                {
                    name: this.$t("dateDeleted"),
                    text: "dateOfDelete",
                    align: "right",
                    status: true
                },
                {
                    name: this.$t("statusDeleted"),
                    text: "canDelete",
                    align: "right",
                    status: true,
                    mutate: (item, obj) => this.mutateStatusDel(item, obj).name,
                    mutateClass: (item, obj) => this.mutateStatusDel(item, obj).color
                },
            ],
            rows: [],
            objcard: {},
        };
    },
    created() {
        this.getdata();
    },
    methods: {
        getdata(page){
            this.objParams.page = page != undefined ? page : '1';
            apiServe.getListDeleted(this.objParams).then(result => {
                if(result.status == 'done') {
                    this.rows = result.data.items
                }
            })
        },
        open: function(e){
            apiServe.getCardDeleted(e.accountId).then(result => {
                if(result.status == 'done') {
                    this.objcard = result.data
                    this.showModal = true
                }
            })
        },
        mutateStatusDel(e, obj){
            return mutateStatusDel(e, obj)
        },
        searchB(e){
            this.search = e
            this.getdata(this.search)
        },
    },
    computed: {
        user() {
            return storeS.userbase
        },
        perms() {
            return storeS.perms
        }
    },
    mounted() {
        }
    };
</script>

<style scoped>
.tr_etner:hover {
cursor: pointer;
background: #f3f3f9;
}
</style>
